/**
 * Created by Mikhail Menshenin on 07.03.2025
 */

var SlotMachinePaylineView = cc.Node.extend({
    ctor: function (slotMachine) {
        this._super();
        this.setAnchorPoint(0, 0);

        var fg = new cc.Sprite(bundles.slotmachine.frames.slot_machine_frame_png);
        this.setContentSize2(fg.getContentSize());
        fg.setLocalZOrder(10);
        fg.setPosition(this.width / 2, this.height / 2);
        this.addChild(fg);

        var bg = new cc.Sprite(bundles.slotmachine.frames.inner_part_png);
        bg.setPosition(this.width / 2, this.height / 2);
        this.addChild(bg);

        var reelViews = this.reelViews = slotMachine.reels.map(function (reel) {
            return new SlotMachineReelView(reel);
        }, this);
        var reelsLayout = new cleverapps.Layout(reelViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.SlotMachinePaylineView.margin
        });
        reelsLayout.setPosition(this.width / 2, this.height / 2);
        this.addChild(reelsLayout);
    },

    getCurrentSymbolViews: function () {
        return this.reelViews.map(function (reelView) {
            return reelView.getCurrentSymbolView();
        });
    }
});

cleverapps.styles.SlotMachinePaylineView = {
    margin: 75
};