/**
 * Created by Mikhail Menshenin on 27.02.2025
 */

var VictoryMessageContentView = cc.Node.extend({
    ctor: function (message) {
        this._super();

        var styles = cleverapps.styles.VictoryMessageContentView;
        var options = {};
        if (cleverapps.styles.FONTS.GAME_MESSAGE_TEXT_VERTICAL) {
            options.font = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.FONTS.GAME_MESSAGE_TEXT_VERTICAL : cleverapps.styles.FONTS.GAME_MESSAGE_TEXT;
        }
        var text = GameMessageView.createText(message, options);
        text.setPositionRound(styles.text.position);
        this.addChild(text);

        var animation = VictoryMessageContentView.getAnimation();
        animation.setPositionRound(styles.animation.position);
        this.addChild(animation);
        animation.setVisible(false);
        animation.runAction(new cc.Sequence(
            new cc.DelayTime(cleverapps.config.name === "yatzy" ? 0 : 0.3),
            new cc.Show(),
            new cc.SpineAction(animation, "animation")
        ));
    }
});

VictoryMessageContentView.getAnimation = function () {
    var json = bundles.gamemessage.jsons.victory_message_json;
    if (bundles.gamemessage.jsons.victory_message_vertical_json) {
        json = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL
            ? bundles.gamemessage.jsons.victory_message_vertical_json
            : json;
    }
    return new cleverapps.Spine(json);
};

VictoryMessageContentView.getAnimationDuration = function () {
    return VictoryMessageContentView.getAnimation().getAnimationDuration("animation");
};

cleverapps.styles.VictoryMessageContentView = {
    text: {
        position: [{
            x: { align: "center", dx: 150 },
            y: { align: "center" }
        }, {
            x: { align: "center", dx: 100 },
            y: { align: "center" }
        }, {
            x: { align: "center", dx: 100 },
            y: { align: "center" }
        }]
    },

    animation: {
        position: [{
            x: { align: "center", dx: 210 },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "center" }
        }]
    }
};