/**
 * Created by Slava Ivanov on 03.04.2025
 */

Meta.prototype.createAdapter = function () {
    var types = {
        colorsort: "simple",
        yatzy: "simple",
        coinkingdom: "village"
    };

    switch (types[cleverapps.config.name]) {
        case "hose":
            return new HoseAdapter();
        case "shortmeta":
            return new ShortMetaAdapter();
        case "simple":
            return new SimpleAdapter();
        case "homefix":
            return new HomefixAdapter();
        case "farm":
            return new FarmAdapter();
        case "merge":
            return new MergeMetaAdapter();
    }

    return new DefaultMetaAdapter();
};

Meta.prototype.createLogic = function () {
    var types = {
        colorsort: "simple",
        yatzy: "simple",
        coinkingdom: "village"
    };

    switch (types[cleverapps.config.name]) {
        case "shortmeta":
        case "hose":
        case "simple":
            return new SimpleLogic();
        case "farm":
            return new FarmLogic();
        case "homefix":
            return new HomefixLogic();
        case "merge":
            return new MergeLogic();
    }

    return new NoMetaLogic();
};