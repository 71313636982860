/**
 * Created by Mikhail Menshenin on 10.09.2024
 */

levels.User.prototype.getCurrentLevel = function () {
    return new Level(0, 0);
};

cleverapps.isLevels = function () {
    return false;
};
