/**
 * Created by Slava on 14.10.2024
 */

LevelMasteryWindow.prototype.createContent = function (styles) {
    var content = this.content = new cc.Node();
    content.setContentSize2(styles);

    var bg = new cc.Sprite(bundles.levelmasterywindow.frames.levelmastery_window_bg);
    bg.setPositionRound(styles.bg);
    content.addChild(bg);
    cleverapps.UI.fitToBox(bg, styles.bg);

    var dragon = new cc.Sprite(bundles.levelmasterywindow.frames.dragon);
    dragon.setPositionRound(styles.dragon);
    content.addChild(dragon, 10);
    cleverapps.UI.fitToBox(dragon, styles.dragon);

    var gridScroll = this.gridScroll = this.createGridScroll(styles.grid, 0);
    gridScroll.setPositionRound(styles.grid);
    content.addChild(gridScroll);

    var fg = new cc.Sprite(bundles.levelmasterywindow.frames.levelmastery_window_fg);
    fg.setPositionRound(styles.fg);
    content.addChild(fg, 11);
    cleverapps.UI.fitToBox(fg, styles.fg);

    var grandPrize = this.createGrandPrize(styles);
    grandPrize.setPositionRound(styles.grandPrizeBg);
    content.addChild(grandPrize);

    var progressBar = this.progressBar = new LevelMasteryView();
    progressBar.setPositionRound(styles.progressBar);
    content.addChild(progressBar, 12);

    var foreground = new cc.Sprite(bundles.levelmasterywindow.frames.foreground);
    foreground.setPositionRound(styles.foreground);
    foreground.setScale(styles.foreground.width / foreground.width);
    content.addChild(foreground);

    var timer = this.createTimer(styles.timer);
    content.addChild(timer);

    return content;
};

LevelMasteryWindow.prototype.popUpAnimation = cleverapps.extendFunc(LevelMasteryWindow.prototype.popUpAnimation, function () {
    this._super();
});

LevelMasteryWindow.prototype.createGrandPrize = function (styles) {
    var grandPrizeBg = cleverapps.UI.createScale9Sprite(bundles.levelmasterywindow.frames.grandprize_backing);
    grandPrizeBg.setContentSize(styles.grandPrizeBg);

    var grandPrizeBg2 = cleverapps.UI.createScale9Sprite(bundles.levelmasterywindow.frames.grandprize_backing_2);
    grandPrizeBg2.setContentSize(styles.grandPrizeBg.bg2);
    grandPrizeBg2.setPositionRound(styles.grandPrizeBg.bg2);
    grandPrizeBg.addChild(grandPrizeBg2);

    var grandText = cleverapps.UI.generateOnlyText("LevelMasteryWindow.GrandPrize", cleverapps.styles.FONTS.LEVELMASTERY_GRAND_PRIZE_TEXT);
    grandText.setPositionRound(styles.grandPrizeBg.text);
    grandPrizeBg.addChild(grandText);

    var icon = new cc.Sprite(bundles.slotmachine.frames.energy_png);
    icon.setPositionRound(styles.grandPrizeBg.rewards);
    cleverapps.UI.fitToBox(icon, styles.grandPrizeBg.rewards);
    grandPrizeBg.addChild(icon);

    var count = "500k";
    var text = cleverapps.UI.generateOnlyText(count, cleverapps.styles.FONTS.LEVELMASTERY_REWARDS_TEXT);
    text.setPositionRound(styles.grandPrizeBg.rewards.text);
    grandPrizeBg.addChild(text);

    StandartAnimations.rays(icon, { skin: "yellow" });

    return grandPrizeBg;
};

LevelMasteryWindow.prototype.onWindowLoaded = cleverapps.extendFunc(LevelMasteryWindow.prototype.onWindowLoaded, function (mission) {
    this._super(mission);

    var styles = cleverapps.styles.LevelMasteryWindow;
    this.windowTitle.type.position.x.dx = styles.title.dx;
    this.windowTitle.replaceParentSamePlace(this.content);

    this.closeButton.baseScale = 0.8;
    this.closeButton.show = function () {
        this.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0, 0.2),
                new cc.Show(),
                new cc.ScaleTo(0.4, 0.8).easing(cc.easeBackOut())
            )
        );
    };
});

LevelMasteryWindow.prototype.createRewardCard = function (styles, rewards, stage) {
    var isCompleted = aisensia.levelMastery.isCompleted(stage);
    var isCurrent = aisensia.levelMastery.isCurrent(stage);
    var isLocked = !isCurrent && !isCompleted;

    var card = cleverapps.UI.createScale9Sprite(isCompleted ? bundles.levelmastery.frames.card_bg_white_png : bundles.levelmastery.frames.card_bg_png);
    card.setAnchorPoint(0.5, 0.5);
    card.setContentSize(styles);

    if (isCurrent) {
        var rays = new cc.Sprite(bundles.levelmastery.frames.card_rays_png);
        rays.setPositionRound(styles.rays);
        rays.setScale(styles.rays.width / rays.width, styles.rays.height / rays.height);
        card.addChild(rays);
    }

    var icon = isCompleted ? new cc.Sprite(bundles.levelmastery.frames.card_mark_png) : this.createRewardView(rewards, isCurrent);
    icon.setPositionRound(styles.icon);
    cleverapps.UI.fitToBox(icon, styles.icon);
    card.addChild(icon);

    if (isLocked) {
        var lock = new cc.Sprite(bundles.levelmastery.frames.card_lock_png);
        lock.setPositionRound(styles.lock);
        card.addChild(lock);
    }

    return card;
};

LevelMasteryWindow.prototype.createRewardView = function (rewards, isCurrent) {
    var node = new cc.Node();
    node.setAnchorPoint(0.5, 0.5);

    var styles = cleverapps.styles.LevelMasteryWindow.rewards;
    node.setContentSize(styles.icon);

    var items = [];

    rewards.forEach(function (reward, index) {
        if (index > 0) {
            var plus = new cc.Sprite(bundles.levelmastery.frames.plus_blue);
            plus.setAnchorPoint(0.5, 0.5);
            items.push(plus);
        }

        var iconNode = new cc.Node();
        iconNode.setContentSize(styles.icon);
        iconNode.setAnchorPoint(0.5, 0.5);

        var icon = this.icon = reward.getIcon();
        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.setPositionRound(styles.icon);
        iconNode.addChild(icon);
        iconNode.setContentSize(icon.width * icon.scale, iconNode.height);

        var text = this.text = cleverapps.UI.generateOnlyText(aisensia.getNumberWithPostfix(reward.getText()), cleverapps.styles.FONTS.LEVELMASTERY_REWARD_TEXT_BIG);
        text.setPositionRound(styles.text);
        iconNode.addChild(text);

        items.push(iconNode);
    });

    var layout = new cleverapps.Layout(items, {
        direction: cleverapps.UI.HORIZONTAL,
        margin: styles.layout.margin
    });
    layout.setPositionRound(styles.layout);

    node.addChild(layout);

    if (rewards.length > 1 && isCurrent) {
        var timer = this.createRowTimer();
        timer.setPositionRound(styles.timer);
        node.addChild(timer);
    }

    return node;
};

LevelMasteryWindow.prototype.createRowTimer = function () {
    var styles = cleverapps.styles.LevelMasteryWindow.rewards.timer;
    var timer = new cleverapps.CountDown(aisensia.levelMastery.getTimeLeft(), {
        onFinish: function () {
            this.stopTimer();
        }.bind(this)
    });

    var timerLabel = cleverapps.UI.createScale9Sprite(bundles.levelmastery.frames.timer_png);
    timerLabel.setContentSize(styles.background);
    timerLabel.setPositionRound(styles.background);

    var textTimer = new cleverapps.CountDownView(timer, { font: cleverapps.styles.FONTS.LEVELMASTERY_TIMER_TEXT });
    textTimer.setPositionRound(styles.text);
    timerLabel.addChild(textTimer);

    return timerLabel;
};

LevelMasteryWindow.prototype.createCircle = cleverapps.extendFunc(LevelMasteryWindow.prototype.createCircle, function (styles, stage) {
    var circle = this._super(styles, stage);

    if (stage !== 0) {
        var line = cleverapps.UI.createScale9Sprite(bundles.levelmastery.frames.line_png);
        line.setLocalZOrder(-10);
        line.setAnchorPoint(0.5, 0);
        line.setContentSize(styles.line_bottom);
        line.setPositionRound(styles.line_bottom);
        circle.addChild(line);
    }

    return circle;
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELMASTERY_GRAND_PRIZE_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.DARK_BROWN_COLOR,
        name: "nostroke"
    },

    LEVELMASTERY_REWARDS_TEXT: {
        size: 30,
        color: new cc.Color(141, 218, 3, 255),
        stroke: {
            color: new cc.Color(5, 124, 25, 255),
            size: 2
        },
    },

    LEVELMASTERY_PLUS: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_WHITE_TEXT_STROKE
    },

    LEVELMASTERY_TIMER_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
});

cleverapps.overrideStyles(cleverapps.styles.LevelMasteryWindow, {
    width: 725,
    height: 1070,

    rewards: {
        layout: {
            x: { align: "center" },
            y: { align: "center" },
            margin: 15
        },

        icon: {
            x: { align: "center" },
            y: { align: "center" },
            width: 90,
            height: 80
        },

        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -35 }
        },

        timer: {
            x: { align: "center", dx: 75 },
            y: { align: "center", dy: -75 },
            background: {
                x: { align: "center" },
                y: { align: "center", dy: 0 },
                width: 150,
                height: 50,
            },
            text: {
                x: { align: "center" },
                y: { align: "center", dy: 0 },
            }
        }
    },

    CloseButton: {
        x: { align: "right", dx: -36 },
        y: { align: "top", dy: -31 }
    },

    fg: {
        x: { align: "center", dx: -251 },
        y: { align: "center", dy: 287 },
        width: 190,
        height: 190
    },

    bg: {
        x: { align: "center" },
        y: { align: "bottom", dy: -205 },
        width: 700,
        height: 2000
    },

    foreground: {
        x: { align: "center" },
        y: { align: "bottom", dy: 17 },
        width: 700
    },

    progressBar: {
        x: { align: "center", dx: -23 },
        y: { align: "center", dy: 250 },
        width: 610,
        height: 200
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: -25 },
        background: {
            frame: bundles.sidebar.frames.icon_text_bg,
            width: 280
        }
    },

    dragon: {
        x: { align: "center", dx: -284 },
        y: { align: "center", dy: 430 },
        width: 220,
        height: 300
    },

    title: {
        dx: 20
    },

    grandPrizeBg: {
        x: { align: "center", dx: 60 },
        y: { align: "top", dy: -90 },
        width: 410,
        height: 115,

        bg2: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -40 },
            width: 240,
            height: 50,
        },

        text: {
            x: { align: "center" },
            y: { align: "bottom", dy: -30 },
        },

        rewards: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 5 },
            width: 60,
            height: 75,
            margin: 35,
            text: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -20 },
            },
        }
    },

    grid: {
        x: { align: "center", dx: -20 },
        y: { align: "top", dy: -310 },
        width: 800,
        height: 720,

        margin: 20,
        padding: {
            top: 60,
            bottom: 60
        },

        row: {
            margin: 90,
            padding: {
                left: 50,
                right: 0
            },

            circle: {
                line: {
                    width: 27,
                    height: 90,
                    x: { align: "center" },
                    y: { align: "center", dy: 43 }
                },
                line_bottom: {
                    width: 27,
                    height: 90,
                    x: { align: "center" },
                    y: { align: "center", dy: -42 }
                }
            },

            card: {
                width: 320,
                height: 155,

                rays: {
                    x: { align: "center" },
                    y: { align: "center", dy: 3 },
                    width: 300,
                    height: 130
                },

                icon: {
                    x: { align: "center" },
                    y: { align: "center", dy: 5 },
                    width: 270,
                    height: 90
                },

                lock: {
                    x: { align: "right", dx: 0 },
                    y: { align: "bottom", dy: -10 }
                }
            }
        }
    },
});