/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var CoinKingdomScene = GameSceneBase.extend({
    onSceneLoaded: function () {
        var styles = cleverapps.styles.CoinKingdomScene;

        this._super(cleverapps.Environment.SCENE_SLOT_MACHINE);

        var slotMachine = this.slotMachine = aisensia.slotMachine;
        var cloudsBundle = bundles[this.getBackgroundStyles().bundle];

        var slotMachineView = this.slotMachineView = new SlotMachineView(slotMachine)
        slotMachineView.setAnchorPoint(0.5, 0);
        slotMachineView.setScale2(styles.slotmachine.scale);
        slotMachineView.setPositionRound(styles.slotmachine.position);
        this.addChild(slotMachineView);

        cleverapps.focusManager.registerControl("slotMachine", new HidingNode(slotMachineView, HidingNode.DIR.NONE));

        var clouds2 = this.makeCloudsLayer(cloudsBundle.frames.clouds2_png);
        clouds2.setAnchorPoint(0.5, 1);
        clouds2.setPositionRound(styles.clouds2.position);
        this.addChild(clouds2);

        var button = new SlotMachineButtonView(slotMachine);
        button.setAnchorPoint(0.5, 0);
        button.setPositionRound(styles.button.position);
        this.addChild(button);
        this.button = new HidingNode(button, HidingNode.DIR.NONE);

        cleverapps.focusManager.registerControl("button", this.button);

        var clouds3 = this.makeCloudsLayer(cloudsBundle.frames.clouds3_png);
        clouds3.setAnchorPoint(0.5, 1);
        clouds3.setPositionRound(styles.clouds3.position);
        this.addChild(clouds3);

        this.addSlotMachineToolbar();

        this.upMenuContainer.addShields();

        if (this.slotMachine.isBeforeFirstSpin()) {
            this.forceSpin();
        }
    },

    makeCloudsLayer: function (frame) {
        var sprites = cleverapps.rangeArray(0, 2).map(function () {
            var sprite = new cc.Sprite(frame);
            var sceneSize = cleverapps.resolution.getSceneSize();
            cleverapps.UI.fitToBox(sprite, sceneSize);
            return sprite;
        });
        return new cleverapps.Layout(sprites, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: 0,
            padding: 0
        });
    },

    addSlotMachineToolbar: function () {
        var toolbarView = new ToolbarView();
        toolbarView.setLocalZOrder(1);
        this.addChild(toolbarView);

        this.downToolBarControl = new HidingNode(toolbarView, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("toolbar", this.downToolBarControl);
    },

    forceSpin: function () {
        var styles = cleverapps.styles.CoinKingdomScene;
        var buttonHitPoint = new cc.Node();
        buttonHitPoint.setPositionRound(styles.hit);
        this.button.addChild(buttonHitPoint);

        cleverapps.focusManager.displayWhenFreeFocus({
            focus: "firstSpinForce",
            control: ["slotMachine", "button"],
            action: function (f) {
                cleverapps.forces.create(buttonHitPoint, {
                    text: "Tutorial.SlotMachine.FirstSpinForce"
                }, {
                    actives: [this.slotMachineView.payline, this.button]
                });
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        });
    },

    onExit: function () {
        this._super();

        this.slotMachine.purge();
    },

    listBundles: function () {
        var bundles = this._super() || [];

        bundles.push("transition_attack");
        bundles.push("transition_raid");

        return bundles;
    },

    _closeAction: cleverapps.FixedWidthScene.prototype._closeAction
});

GameScene = CoinKingdomScene;

cleverapps.styles.CoinKingdomScene = {
    slotmachine: {
        scale: [0.87, 1, 1],
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: -130 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -209 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -209 }
        }]
    },

    button: {
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 0 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 21 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 21 }
        }]
    },

    hit: {
        x: { align: "center" },
        y: { align: "center", dy: 100 }
    },

    clouds2: {
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 150 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 60 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 60 }
        }]
    },

    clouds3: {
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 20 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 36 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 36 }
        }]
    }
};
