/**
 * Created by Mikhail Menshenin on 28.06.2024
 */

MiniGameWindow.prototype.createBoxesNode = cleverapps.extendFunc(MiniGameWindow.prototype.createBoxesNode, function () {
    var node = this._super();
    var style = cleverapps.styles.MiniGameWindow;

    if (bundles.minigame && bundles.minigame.frames.minigame_bg_png) {
        var bg = new cc.Sprite(bundles.minigame.frames.minigame_bg_png);
        bg.setScale(style.bg.width / bg.width, style.bg.height / bg.height);
        bg.setPositionRound(style.bg);
        bg.setLocalZOrder(-1);
        node.addChild(bg);
    }

    return node;
});

MiniGameWindow.prototype.showUpAnimation = function () {
    var boxes = this.boxes;

    var positions = boxes.map(function (box) {
        return box.getPosition();
    });

    var targets = [[2, 0, 1], [0, 2, 1], [1, 0, 2]];
    this.text.setOpacity(0);
    var rotations = [[20, -20, 20, -20], [-20, 20, -20, 20], [20, 0, -20, 0]];

    var localZOrders = [3, 1, 2];
    boxes.forEach(function (box, id) {
        box.setLocalZOrder(localZOrders[id]);
    });

    cleverapps.focusManager.compound(function () {
        cleverapps.focusManager.setEventNodes([cleverapps.scenes.getRunningScene()]);
    }, [
        function (f) {
            boxes.forEach(function (box, id) {
                box.runAction(new cc.Sequence(
                    new cc.DelayTime(0.5),
                    new cc.CallFunc(function () {
                        if (id === 0) {
                            var playNo = cleverapps.Random.random(3) + 1;
                            cleverapps.audio.playSound(bundles.minigame.urls["dailygame_" + playNo]);
                        }
                    }),
                    new cc.Spawn(
                        new cc.MoveTo(0.3, positions[targets[0][id]]),
                        new cc.Sequence(
                            new cc.RotateBy(0.2, rotations[id][0]),
                            new cc.RotateBy(0.1, -rotations[id][0])
                        )
                    ),
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(function () {
                        box.setLocalZOrder(localZOrders[targets[1][id]]);
                        if (id === 0) {
                            var playNo = cleverapps.Random.random(3) + 1;
                            cleverapps.audio.playSound(bundles.minigame.urls["dailygame_" + playNo]);
                        }
                    }),
                    new cc.Spawn(
                        new cc.MoveTo(0.3, positions[targets[1][id]]),
                        new cc.Sequence(
                            new cc.RotateBy(0.2, rotations[id][1]),
                            new cc.RotateBy(0.1, -rotations[id][1])
                        )
                    ),
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(function () {
                        box.setLocalZOrder(localZOrders[targets[2][id]]);
                        if (id === 0) {
                            var playNo = cleverapps.Random.random(3) + 1;
                            cleverapps.audio.playSound(bundles.minigame.urls["dailygame_" + playNo]);
                        }
                    }),
                    new cc.Spawn(
                        new cc.MoveTo(0.3, positions[targets[2][id]]),
                        new cc.Sequence(
                            new cc.RotateBy(0.2, rotations[id][2]),
                            new cc.RotateBy(0.1, -rotations[id][2])
                        )
                    ),
                    new cc.DelayTime(0.1),
                    new cc.CallFunc(function () {
                        box.setLocalZOrder(localZOrders[id]);
                        if (id === 0) {
                            var playNo = cleverapps.Random.random(3) + 1;
                            cleverapps.audio.playSound(bundles.minigame.urls["dailygame_" + playNo]);
                        }
                    }),
                    new cc.Spawn(
                        new cc.MoveTo(0.3, positions[id]),
                        new cc.Sequence(
                            new cc.RotateBy(0.2, rotations[id][3]),
                            new cc.RotateBy(0.1, -rotations[id][3])
                        )
                    ),
                    new cc.DelayTime(0.3),
                    new cc.CallFunc(function () {
                        if (id === 0) {
                            this.text.runAction(
                                new cc.FadeIn(0.5)
                            );
                        }
                        f();
                    }.bind(this)),
                    new cc.DelayTime(id * 0.5),
                    new cc.CallFunc(function () {
                        box.setAnimation(0, "animation", true);
                    })
                ));
            }, this);
        }.bind(this)
    ]);
};

MiniGameWindow.prototype.getPerson = function () {
    return "hero";
};

cleverapps.overrideStyles(cleverapps.styles.MiniGameWindow, {
    size: {
        width: 750,
        height: 600
    },

    box: {
        x: { align: "center", dx: 10 },
        y: { align: "center" }
    },

    target: {
        offsetY: -80
    },

    center: {
        offsetY: -140
    },

    boxes: [
        {
            x: -161,
            y: -35
        },
        {
            x: 24,
            y: 47
        },
        {
            x: 210,
            y: -36
        }
    ],

    bg: {
        x: { align: "center", dx: 17 },
        y: { align: "center", dy: -124 },
        width: 825,
        height: 400
    },

    animation: {
        x: { align: "center", dx: -10 },
        y: { align: "center", dy: 138 }
    },

    text: {
        width: 650,
        x: { align: "center" },
        y: { align: "bottom", dy: -20 }
    }
});