/**
 * Created by Mikhail Menshenin on 09.08.2024
 */

var SlotMachine = function () {
    cleverapps.EventEmitter.call(this);

    this.counter = new Counter();
    this.state = SlotMachine.IDLE_STATE;
    this.autoSpin = false;
    this.message = "";
    this.spins = 0;
    this.reels = [];
    for (var i = 0; i < SlotMachine.REELS_AMOUNT; i++) {
        this.reels.push(new SlotMachineReel(i));
    }

    this.levelMasteryRewardList = [0, 1, 3, 5];
    this.levelMasteryTargetType = SlotMachine.ATTACK_SYMBOL.type;

    this.counter.registerStage(1, this.afterSpin.bind(this));
    this.counter.registerStage(20, this.showMessage.bind(this));
    this.counter.registerStage(30, this.receiveReward.bind(this));
    this.counter.registerStage(40, this.doAction.bind(this));
    this.counter.registerStage(50, this.doAutoSpin.bind(this));
    this.counter.registerStage(60, this.outOfSpins.bind(this));
};

SlotMachine.prototype = Object.create(cleverapps.EventEmitter.prototype);
SlotMachine.prototype.constructor = SlotMachine;

SlotMachine.prototype.init = function () {
    this.energy = this.getEnergy();
    cleverapps.lives.onChangeAmountListeners.slotMachineListener = this.onChangeEnergy.bind(this);
    cleverapps.shields.on("change", this.checkShieldExceeded.bind(this));
};

SlotMachine.prototype.isForceAvailable = function () {
    return !cleverapps.forces.isShown(Forces.SLOT_MACHINE.id);
};

SlotMachine.prototype.isHelpForceAvailable = function () {
    return !cleverapps.forces.isShown(Forces.SLOT_MACHINE_HELP.id);
};

SlotMachine.prototype.getEnergy = function () {
    return cleverapps.lives.amount;
};

SlotMachine.prototype.getEnergyOver = function () {
    return this.getEnergy() - this.getMaxEnergy();
};

SlotMachine.prototype.setEnergy = function (value, silent) {
    cleverapps.lives.setAmount(value, silent);
};

SlotMachine.prototype.addEnergy = function (value, silent, delta) {
    this.setEnergy(this.getEnergy() + value, silent);
    if (delta) {
        this.trigger("deltaEnergy", value);
    }
};

SlotMachine.prototype.getMaxEnergy = function () {
    return cleverapps.lives.getMaxLives();
};

SlotMachine.prototype.isFullEnergy = function () {
    return this.getEnergy() >= this.getMaxEnergy();
};

SlotMachine.prototype.getEnergyRestoreTimeLeft = function () {
    return cleverapps.lives.calcTimeLeft();
};

SlotMachine.prototype.canSpin = function () {
    return cleverapps.lives.canTake(this.getSpinCost());
};

SlotMachine.prototype.getSpinCost = function () {
    return 1;
};

SlotMachine.prototype.spin = function () {
    if (this.counter.isActive()) {
        return false;
    }

    if (!(this.state === SlotMachine.IDLE_STATE || this.state === SlotMachine.DO_ACTION_STATE)) {
        return false;
    }

    if (!this.canSpin()) {
        this.counter.inc();
        this.trigger("spin", function () {
            this.counter.dec();
        }.bind(this), true);
        return false;
    }

    cleverapps.lives.setAmount(cleverapps.lives.amount - this.getSpinCost());

    var payline = this.calcPayline();
    this.force = this.calcForce();
    this.incSpins();

    var reelSpinCount = cleverapps.Random.random(17, 19);

    this.reels.forEach(function (reel, index) {
        this.counter.inc();
        this.counter.setTimeout(function () {
            reel.spin(payline[index], reelSpinCount, function () {
                this.counter.dec();
            }.bind(this));
        }.bind(this), index * SlotMachine.REEL_SPIN_DELAY);
    }.bind(this));

    this.state = SlotMachine.SPIN_STATE;

    this.trigger("spin");

    return true;
};

SlotMachine.prototype.incSpins = function () {
    this.spins++;
    this.save();
};

SlotMachine.prototype.doAutoSpin = function () {
    if (this.autoSpin) {
        this.spin(cleverapps.forces.isRunningForce());
    }
};

SlotMachine.prototype.outOfSpins = function () {
    if (!cleverapps.lives.amount) {
        cleverapps.focusManager.display({
            focus: "OutOfEnergyWindow",
            control: [],
            action: function (f) {
                new OutOfEnergyWindow(this);
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });
    }
};

SlotMachine.prototype.getMessage = function () {
    var payline = this.getCurrentPayline();
    var config = SlotMachine.getMatchPaylineConfig(payline) || {};
    var message = config.message || "";
    var reward = config.reward;
    if (typeof reward == "function") {
        reward = reward();
    }

    if (reward) {
        if (reward.soft) {
            message = aisensia.getNumberWithCommas(reward.soft) + " " + TextWithIcon.ICONS_BY_NAME.soft;
        }

        if (reward.energy) {
            message = reward.energy + " spins";
        }
    }

    return message;
};

SlotMachine.prototype.afterSpin = function () {
    if (this.state === SlotMachine.SPIN_STATE) {
        this.state = SlotMachine.SHOW_MESSAGE_STATE;
    }
};

SlotMachine.prototype.showMessage = function () {
    if (this.state === SlotMachine.SHOW_MESSAGE_STATE) {
        this.message = this.getMessage();
        this.counter.inc();
        this.trigger("message", this.message, function () {
            this.showPaylineForce();
            this.counter.dec();
        }.bind(this));
        this.state = SlotMachine.REWARD_RECIEVE_STATE;
    }
};

SlotMachine.prototype.showPaylineForce = function () {
    if (this.force) {
        this.counter.inc();
        this.trigger("force", this.force, function () {
            this.counter.dec();
        }.bind(this));
    }
};

SlotMachine.prototype.receiveReward = function () {
    if (this.state !== SlotMachine.REWARD_RECIEVE_STATE) {
        return;
    }

    var payline = this.getCurrentPayline();
    var config = SlotMachine.getMatchPaylineConfig(payline) || {};
    var reward = config.reward;
    var action = config.action;

    if (typeof reward == "function") {
        reward = reward();
    }

    if (reward) {
        var rewardList = new RewardsList(reward, { event: cleverapps.EVENTS.EARN.SLOTMACHINE });
        rewardList.receiveRewards();

        if (reward.soft) {
            this.counter.inc();
            this.trigger("coinsRecieve", config.animation, function () {
                rewardList.onAnimationFinished();
                this.counter.dec();
            }.bind(this));
        }
    }

    if (config.icon_animation) {
        var isEnergyReward = reward && reward.energy;
        if (isEnergyReward) {
            rewardList.onAnimationFinished();
        }
        this.counter.inc();
        this.trigger("iconsRecieve", config, function () {
            this.counter.dec();
            if (reward && !isEnergyReward) {
                rewardList.onAnimationFinished();
            }
        }.bind(this));
    }

    if (config.sound) {
        cleverapps.audio.playSound(config.sound);
    }

    if (action) {
        cleverapps.focusManager.display({
            focus: "SlotMachineAction",
            control: [],
            action: function (f) {
                this.actionReleaseCallback = f;
            }.bind(this)
        });
    }

    if (aisensia.levelMastery.isAvailable) {
        this.receiveLevelMasteryReward();
    }

    this.state = SlotMachine.DO_ACTION_STATE;
};

SlotMachine.prototype.doAction = function () {
    if (this.state === SlotMachine.DO_ACTION_STATE) {
        this.state = SlotMachine.IDLE_STATE;
        var payline = this.getCurrentPayline();
        var config = SlotMachine.getMatchPaylineConfig(payline) || {};
        var action = config.action;
        if (action) {
            this.counter.inc();
            action(function () {
                this.counter.dec();
            }.bind(this));
        }
        if (this.actionReleaseCallback) {
            this.actionReleaseCallback();
            delete this.actionReleaseCallback;
        }
    }
};

SlotMachine.prototype.calcPayline = function () {
    var payline = [];
    var excludePaylineSymbols = false;
    if (cleverapps.config.debugMode) {
        if (SlotMachine.PREDEFINED_PAYLINE) {
            payline = cleverapps.clone(SlotMachine.PREDEFINED_PAYLINE);
            excludePaylineSymbols = true;
        } else {
            if (this.debugPayline === undefined) {
                this.debugPayline = 0;
            }
            payline = SlotMachine.DEBUG_PAYLINES[this.debugPayline++ % SlotMachine.DEBUG_PAYLINES.length];
            if (typeof payline[0] != "string") {
                payline = cleverapps.Random.choose(payline);
            }
        }
    }
    if (SlotMachine.FIRST_SESSION_PAYLINES[this.spins]) {
        payline = cleverapps.clone(SlotMachine.FIRST_SESSION_PAYLINES[this.spins]);
        excludePaylineSymbols = true;
    }
    for (var i = payline.length; i < SlotMachine.REELS_AMOUNT; i++) {
        var symbols = payline.reduce(function (symbols, type) {
            var exclude = SlotMachine.SYMBOLS[type].exclude || [];

            if (excludePaylineSymbols) {
                exclude.push(type);
            }

            return symbols.filter(function (symbol) {
                return !exclude.includes(symbol.type);
            });
        }, SlotMachineReel.SYMBOLS);
        payline.push(cleverapps.Random.choose(symbols).type);
    }

    return payline;
};

SlotMachine.prototype.calcForce = function () {
    var force = SlotMachine.FORCES.find(function (force) {
        return force.spin === this.spins;
    }.bind(this));
    return force && force.message;
};

SlotMachine.prototype.onChangeEnergy = function () {
    var energy = this.getEnergy();
    var delta = energy - this.energy;

    if (delta) {
        this.energy = energy;
        this.trigger("changeEnergy", delta < 0);
    }
};

SlotMachine.prototype.getCurrentPayline = function () {
    return this.reels.map(function (reel) {
        return reel.getCurrent().type;
    });
};

SlotMachine.prototype.checkShieldExceeded = function () {
    if (cleverapps.shields.exceeded) {
        this.addEnergy(1, false, true);
    }
};

SlotMachine.prototype.receiveLevelMasteryReward = function () {
    var level = aisensia.levelMastery.level;
    var inc = this.getLevelMasteryReward();
    aisensia.levelMastery.setLevel(level + inc);
};

SlotMachine.prototype.getLevelMasteryReward = function () {
    var targetSymbolCount = this.getLevelMasteryTargetSymbolCount();
    return this.levelMasteryRewardList[targetSymbolCount];
};

SlotMachine.prototype.getLevelMasteryTargetSymbolCount = function () {
    var targetType = this.levelMasteryTargetType;
    var count = this.getCurrentPayline().reduce(function (count, type) {
        return count + (type === targetType ? 1 : 0);
    }, 0);
    return count;
};

SlotMachine.prototype.getInfo = function () {
    return {
        spins: this.spins
    }
};

SlotMachine.prototype.load = function (data) {
    data = data || cleverapps.dataLoader.load(DataLoader.TYPES.SLOTMACHINE);
    if (data) {
        this.spins = data.spins || 0;
        this.save(true);
    }
}

SlotMachine.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.SLOTMACHINE, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("slotMachine");
    }
};

SlotMachine.prototype.isBeforeFirstSpin = function () {
    return this.spins === 0;
};

SlotMachine.getMatchPaylineConfig = function (payline) {
    return SlotMachine.PAYLINES.find(function (config) {
        return !payline.reduce(function (payline, type) {
            var index = payline.indexOf(type);

            if (index !== -1) {
                payline.splice(index, 1);
            }

            return payline;
        }, cleverapps.clone(config.payline)).length;
    });
};

SlotMachine.IDLE_STATE = 0;
SlotMachine.SPIN_STATE = 1;
SlotMachine.SHOW_MESSAGE_STATE = 2;
SlotMachine.REWARD_RECIEVE_STATE = 3;
SlotMachine.DO_ACTION_STATE = 4;

cleverapps.InitByFeature["slotmachine"] = function (isNewUser) {
    aisensia.slotMachine = new SlotMachine();
    if (!isNewUser) {
        aisensia.slotMachine.load();
    }
};

cleverapps.whenAllInitialized(function () {
    aisensia.slotMachine.init();
});

DataLoader.TYPES.SLOTMACHINE = "_slotmachine";

CustomSyncers.extractors.slotMachine = function () {
    return aisensia.slotMachine.getInfo();
};

CustomSyncers.importerData.slotMachine = function (data) {
    aisensia.slotMachine.load(data);
};
